import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-color-picker',
	templateUrl: './color-picker.component.html',
	styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit {
	@Input() colors;
	@Input() data;
	@Input() placement;
	@Output() setColorData = new EventEmitter();
	@ViewChild('colorPickerPopover') colorPickerPopover: NgbPopover;

	isMobile = (/Mobi|Android/i.test(navigator.userAgent));
	
	constructor() {
	}
	
	ngOnInit(): void {
	}
	
	onChangeColor(event): void {
		const color = event.target.dataset.color;
		const name = event.target.dataset.colorName;
		
		if (color && name) {
			this.data.color = color;
			this.data.color_name = name;
			this.data.event = event;
			this.setColorData.emit(this.data);
		}

		if (this.isMobile) {
			this.colorPickerPopover.close();
		}
	}
}
