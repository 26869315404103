import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ScreenshotService } from '@core/services/screenshot/screenshot.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { EngineService } from '@core/services/engine/engine.service';
import { SELECTORS, STORAGE_NAMES } from '@root/app.config';

@Injectable({
	providedIn: 'root'
})
export class OverviewLinkHandlerService {
	getScreenShotSaved: Subscription;
	
	constructor(
		private sessionStorageService: SessionStorageService,
		private screenShotService: ScreenshotService,
		private shareService: ShareService,
		private engineService: EngineService,
		private router: Router
	) {
	}
	
	link(callBack?: (value: void) => void): void {
		const lastOpenedBlindId = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_current_blind_id) ||
			this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_last_opened_blind_id);
		this.screenShotService.saveScreenShot(lastOpenedBlindId, true);
		this.getScreenShotSaved = this.shareService.getScreenShotSaved.subscribe(res => {
			void this.router.navigate(['/overview']);
			document.addEventListener('DOMContentLoaded', () => {
				window.scrollTo(0, 0);
			});
			this.addActiveClass();
			this.getScreenShotSaved.unsubscribe();
			
			if (callBack) {
				callBack();
			}
		});

		this.engineService.isModelCreated = false;
	}
	
	addActiveClass(): void {
		const navItems = document.querySelectorAll(SELECTORS.navigation_item);
		const lastNavItem = navItems[navItems.length - 1];

		lastNavItem.classList.add('active');
	}

}
