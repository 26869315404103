import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PassDataService } from '@core/services/pass-data/pass-data.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SELECTORS } from '@root/app.config';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@core/services/modal/modal.service';

declare var $: any;

@Component({
	selector: 'app-ar-loading-modal',
	templateUrl: './ar-loading-modal.component.html',
	styleUrls: ['./ar-loading-modal.component.scss', 'ar-loading-modal.responsive.scss']
})
export class ArLoadingModalComponent implements OnInit, OnDestroy {
	getARStatus: Subscription;
	isArLoadedOnAndroid: boolean = false;
	isARNotCompatible: boolean = false;
	loading: boolean = true;
	
	constructor(
		private matDialogRef: MatDialogRef<ArLoadingModalComponent>,
		private matDialog: MatDialog,
		private passDataService: PassDataService,
		private shareService: ShareService,
		private translateService: TranslateService,
		private modalService: ModalService,
		@Inject(MAT_DIALOG_DATA) public data
	) {
	}
	
	ngOnInit(): void {
		this.passDataService.isLaunchCameraStopped = false;
		this.arStatusHandler();
		this.modalService.liftUpModal(this.matDialogRef);
	}
	
	ngOnDestroy(): void {
		this.getARStatus.unsubscribe();
		$(SELECTORS.ar_viewer).removeClass('open');
	}
	
	onClose(type: string): void {
		this.passDataService.isLaunchCameraStopped = true;
		type === 'close' ? this.matDialogRef.close() : this.matDialog.closeAll();
		$(SELECTORS.ar_viewer).removeClass('open');
	}
	
	arStatusHandler(): void {
		let mainARButtonText: string;
		
		this.getARStatus = this.shareService.getARStatus.subscribe((status: string) => {
			this.loading = status === 'loading';
			this.isARNotCompatible = status === 'not-compatible';
			this.isArLoadedOnAndroid = !['failed', 'not-presenting'].includes(status);
			
			$(SELECTORS.ar_viewer).toggleClass('open', this.isArLoadedOnAndroid && !this.loading);
			
			if (!this.isArLoadedOnAndroid && !this.loading) {
				this.onClose(status);
			}
			
			if (this.isARNotCompatible) {
				mainARButtonText = $(SELECTORS.ar_button).text();
				$(SELECTORS.ar_button).attr('slot', '');
				$(SELECTORS.ar_button).text(this.translateService.instant('modals.ar_loading.close_button'));
				$(SELECTORS.ar_button).on('click', this.onClose.bind(this));
				this.data.model_exported = true;
			}
		});
		
		this.matDialogRef.afterClosed().subscribe(() => {
			$(SELECTORS.ar_viewer).removeClass('open');
			$(SELECTORS.ar_button).text(mainARButtonText);
			$(SELECTORS.ar_button).attr('slot', 'ar-button');
		});
	}
	
}
