import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ROTATE_TYPES } from '@root/app.config';

@Component({
	selector: 'app-rotate-device-modal',
	templateUrl: './rotate-device-modal.component.html',
	styleUrls: ['./rotate-device-modal.component.scss', './rotate-device-modal.responsive.scss']
})
export class RotateDeviceModalComponent implements OnInit {
	rotateTypes = ROTATE_TYPES;
	
	@HostListener('window:orientationchange', ['$event']) onOrientationChange() {
		if (window.orientation === 0 && this.data === ROTATE_TYPES.to_portrait) {
			this.dialogRef.close();
		}
		
		if (window.orientation === 90 && this.data === ROTATE_TYPES.to_landscape) {
			this.dialogRef.close();
		}
	}
	
	constructor(
		public dialogRef: MatDialogRef<RotateDeviceModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data) {
	}
	
	ngOnInit(): void {
	}
	
	closeDialog(): void {
		this.dialogRef.close();
	}
}
