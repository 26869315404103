import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OverviewLinkHandlerService } from '@core/services/overview-link-handler/overview-link-handler.service';
import { EngineService } from '@core/services/engine/engine.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { STORAGE_NAMES, SELECTORS } from '@root/app.config';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';

declare var $: any;

@Component({
	selector: 'app-overview-modal',
	templateUrl: './overview-modal.component.html',
	styleUrls: ['./overview-modal.component.scss', './overview-modal.responsive.scss']
})

export class OverviewModalComponent implements OnInit, OnDestroy {
	
	constructor(
		private engineService: EngineService,
		private shareService: ShareService,
		private sessionStorageService: SessionStorageService,
		private overviewLinkHandlerService: OverviewLinkHandlerService,
		public dialogRef: MatDialogRef<OverviewModalComponent>
	) {
	}
	
	ngOnInit() {
		this.engineService.setGizmoControlVisible(false);
		$(SELECTORS.overlay_container).addClass('internal');
	}
	
	ngOnDestroy() {
		this.engineService.setGizmoControlVisible(true);
		$(SELECTORS.overlay_container).removeClass('internal');
	}
	
	onSeeOverviewButton() {
		const id = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_current_blind_id);
		const type = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_type);
		this.shareService.setBlindSelect({ id, type });
		
		this.overviewLinkHandlerService.link(() => this.dialogRef.close());
	}
	
	close(): void {
		this.dialogRef.close();
	}
	
}
