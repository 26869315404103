import {
	Component,
	OnDestroy,
	OnInit,
	ChangeDetectorRef,
	ViewEncapsulation
} from '@angular/core';
import { EngineService } from '@core/services/engine/engine.service';
import { Subscription } from 'rxjs';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { ServerDataService } from '@core/services/server-data/server-data.service';
import { ActivatedRoute } from '@angular/router';
import { ScreenshotService } from '@core/services/screenshot/screenshot.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { STORAGE_NAMES } from '@root/app.config';
import { BlindListComponent } from '@features/blind-list/blind-list.component';
import { PassDataService } from '@core/services/pass-data/pass-data.service';

import * as _ from 'lodash';

@Component({
	selector: 'app-features-control',
	templateUrl: './features-control.component.html',
	styleUrls: ['../blind-list//blind-list.component.scss', '../blind-list//blind-list.responsive.scss',
		'./features-control.component.scss', './features-control.responsive.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class FeaturesControlComponent extends BlindListComponent implements OnInit, OnDestroy {
	getChanges: Subscription;
	getBlindNameStatus: Subscription;
	
	blind: any;
	blindId: number = JSON.parse(this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_current_blind_id));
	blindsDataIV: any = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data_iv);
	blindIndex: number;
	hasChanges = false;
	
	constructor(
		public shareDataService: ShareService,
		public engineService: EngineService,
		public serverDataService: ServerDataService,
		public saveSessionBlind: SaveSessionBlindsService,
		public route: ActivatedRoute,
		public screenShotService: ScreenshotService,
		public sessionStorageService: SessionStorageService,
		public passDataService: PassDataService,
		public changeDetection: ChangeDetectorRef
	) {
		super(
			shareDataService,
			engineService,
			serverDataService,
			saveSessionBlind,
			route,
			screenShotService,
			sessionStorageService,
			passDataService,
			changeDetection
		);
	}
	
	ngOnInit(): void {
		super.ngOnInit();
		this.getCurrentBlind();
		this.createDuplicateBlindsStorage();
		this.getChangesHandler();
		this.isDesignType = false;
	}
	
	ngOnDestroy(): void {
		super.ngOnDestroy();
		
		this.getChanges.unsubscribe();
		this.getBlindNameStatus.unsubscribe();
	}
	
	getCurrentBlind(): void {
		this.blind = this.blindsData.filter(blind => blind.blind_id === this.blindId)[0];
		this.blindIndex = this.blindsData.indexOf(this.blind);
	}
	
	saveChanges(): void {
		const blindData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data_iv);
		const currentBlind = blindData.filter(blind => blind.blind_id === this.blindId)[0];
		const index = this.blindsData.indexOf(this.blindsData.filter(blind => blind.blind_id === this.blindId)[0]);
		const isSessionSaved = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_session_saved);
		
		currentBlind.setup.size = this.blindsData[index].setup.size;
		this.blindsData.splice(index, 1, currentBlind);
		this.sessionStorageService.setBlindData(this.blindsData, STORAGE_NAMES.zip_blind_data);
		this.shareDataService.setIVData(currentBlind);
		
		if (isSessionSaved) {
			this.saveSessionBlind.PutStorageDataToServer();
		}
		
		this.hasChanges = !this.hasChanges;
	}
	
	createDuplicateBlindsStorage(): void {
		this.blindsDataIV = [this.blind];
		
		this.sessionStorageService.setBlindData(this.blindsDataIV, STORAGE_NAMES.zip_blind_data_iv);
	}
	
	getChangesHandler(): void {
		this.getChanges = this.shareDataService.getIVDataChanged.subscribe(status => this.hasChanges = status);
		this.getBlindNameStatus = this.shareDataService.getStatus.subscribe(status => this.hasChanges = status);
		
		this.changeDetection.detectChanges();
	}
	
}
