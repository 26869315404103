import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SelectBackgroundModalComponent } from '@shared/modals/select-background-modal/select-background-modal.component';
import { OverviewModalComponent } from '@shared/modals/overview-modal/overview-modal.component';
import { SaveWorkModalComponent } from '@shared/modals/save-work-modal/save-work-modal.component';
import { CONFIG, SELECTORS, STORAGE_NAMES, VIEW_TYPES } from '@root/app.config';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { EngineService } from '@core/services/engine/engine.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { Subscription } from 'rxjs';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';

declare var $: any;

@Component({
	selector: 'app-control-panel',
	templateUrl: './control-panel.component.html',
	styleUrls: ['./control-panel.component.scss', './control-panel.responsive.scss']
})
export class ControlPanelComponent implements OnInit {
	getIVResetStatus: Subscription;
	
	isMobile: boolean;
	isOpenControls: boolean = false;
	controlType: string;
	shutterControlStatus = true;
	isDesignType: boolean =
		this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type) === VIEW_TYPES.design;
	
	resetStatus = false;
	resetPopoverPosition = 'left';
	
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.isMobile = this.setupPageService.mobileAndTabletCheck();
		const status = this.isMobile ? false : window.innerWidth > CONFIG.breakpoints['tablet-landscape'];
		const modalStatus = window.innerWidth > CONFIG.breakpoints['small-phone'];
		
		if (status !== this.shutterControlStatus) {
			this.shutterControlStatus = status;
			this.shareService.setIVShutterControl(this.shutterControlStatus);
		}
		
		this.resetPopoverPosition = modalStatus ? 'left top-left' : 'top-left';
	}
	
	constructor(
		private sessionStorageService: SessionStorageService,
		private setupPageService: SetupPageService,
		private engineService: EngineService,
		private shareService: ShareService,
		private matDialog: MatDialog
	) {
	}
	
	ngOnInit(): void {
		this.getIVResetStatus = this.shareService.getIVResetStatus.subscribe((status) => {
			this.resetStatus = status;
		});
		this.onResize(null);
	}
	
	onSelectControl(type: string): void {
		this.isDesignType =
			this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type) === VIEW_TYPES.design;
		this.isOpenControls = true;
		this.controlType = type;
		this.engineService.setGizmoControlVisible(false);
	}
	
	onClose(): void {
		const activeConfigItem = $(`${ SELECTORS.config_item }.active`);
		activeConfigItem.removeClass('active');
		activeConfigItem.children(SELECTORS.config_item_content).hide();
		this.engineService.setGizmoControlVisible(true);
		this.isOpenControls = false;
	}
	
	onBackgroundSelectControl(event) {
		const currentTarget = event.currentTarget;
		$(currentTarget).addClass('active');
		
		const dialogRef = this.matDialog.open(SelectBackgroundModalComponent, {
			width: 'max-content',
			maxWidth: '940px',
			height: 'auto',
			minHeight: '200px',
			panelClass: 'mc-modal__internal'
		});
		
		dialogRef.afterClosed().subscribe(() => $(currentTarget).removeClass('active'));
	}
	
	onSaveControl(event) {
		const currentTarget = event.currentTarget;
		$(currentTarget).addClass('active');
		
		const dialogRef = this.matDialog.open(SaveWorkModalComponent, {
			width: 'max-content',
			maxWidth: '690px',
			height: 'auto',
			minHeight: '200px',
			panelClass: 'mc-modal__internal'
		});
		
		dialogRef.afterClosed().subscribe(() => $(currentTarget).removeClass('active'));
	}
	
	onRequestControl() {
		this.matDialog.open(OverviewModalComponent, {
			width: 'max-content',
			maxWidth: '690px',
			height: 'auto',
			minHeight: '200px',
			panelClass: 'mc-modal__internal'
		});
	}
	
	onShutterControl() {
		this.shutterControlStatus = !this.shutterControlStatus;
		this.shareService.setIVShutterControl(this.shutterControlStatus);
	}
	
	onResetIVScreen() {
		this.shareService.setIVShutterControlClose(true);
		this.engineService.setIVCameraSettings();
		this.engineService.setGizmoControl(true);
		this.engineService.setIVSampleProject();
		this.engineService.resetLineByPoints(false);
	}
}
