import { ChangeDetectorRef, Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { BlindListComponent } from '@features/blind-list/blind-list.component';
import { ShareService } from '@core/services/share-data/share-data.service';
import { EngineService } from '@core/services/engine/engine.service';
import { ServerDataService } from '@core/services/server-data/server-data.service';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { ActivatedRoute } from '@angular/router';
import { ScreenshotService } from '@core/services/screenshot/screenshot.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { PassDataService } from '@core/services/pass-data/pass-data.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-interior-list',
	templateUrl: './interior-list.component.html',
	styleUrls: ['../blind-list.component.scss', '../blind-list.responsive.scss']
})
export class InteriorListComponent extends BlindListComponent implements OnInit, OnChanges {
	@Input() index: number;
	@Input() blindsListComponent: BlindListComponent;
	
	getIVData: Subscription;
	
	blind: any;
	
	constructor(
		public shareDataService: ShareService,
		public engineService: EngineService,
		public serverDataService: ServerDataService,
		public saveSessionBlind: SaveSessionBlindsService,
		public route: ActivatedRoute,
		public screenShotService: ScreenshotService,
		public sessionStorageService: SessionStorageService,
		public passDataService: PassDataService,
		public changeDetection: ChangeDetectorRef
	) {
		super(
			shareDataService,
			engineService,
			serverDataService,
			saveSessionBlind,
			route,
			screenShotService,
			sessionStorageService,
			passDataService,
			changeDetection
		);
	}
	
	ngOnInit(): void {
		super.ngOnInit();
		this.blind = this.blindsData[this.index];
		
		setTimeout(this.blindNameSave.bind(this, this.blind.blind_id));
		this.getModelLoadedStatus.unsubscribe();
		this.getScreenShot.unsubscribe();
		this.getBlindsData.unsubscribe();
		this.setIVBlindDataHandler();
	}
	
	ngOnChanges(changes: SimpleChanges): void {
		if (!changes.index.firstChange) {
			this.blind = this.blindsListComponent.blindsData[this.index];
			this.blindsData = this.blindsListComponent.blindsData;
		}
	}
	
	ngOnDestroy(): void {
		super.ngOnDestroy();
		
		this.getIVData.unsubscribe();
	}
	
	blindNameSave(id: any): void {
		super.blindNameSave(id);
		if (this.blindsListComponent.blindsData) {
			this.blindsListComponent.blindsData[this.index].name = this.blind.name;
		}
	}
	
	setIVBlindDataHandler(): void {
		this.getIVData = this.shareDataService.getIVData
			.subscribe(data => {
				if (data?.blind_id === this.blind.blind_id) {
					Object.assign(this.blind, data);
				}
			});
	}
}
