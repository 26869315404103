import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { EngineService } from '@core/services/engine/engine.service';
import { SELECTORS, STORAGE_NAMES } from '@root/app.config';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import html2canvas from 'html2canvas';

declare var $: any;

@Component({
	selector: 'app-save-work-modal',
	templateUrl: './save-work-modal.component.html',
	styleUrls: ['./save-work-modal.component.scss', './save-work-modal.responsive.scss']
})
export class SaveWorkModalComponent implements OnInit, OnDestroy {
	downloading = false;
	saving = false;
	
	embeddedRetailer = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_embedded_retailer);
	
	constructor(
		private saveSessionBlind: SaveSessionBlindsService,
		private sessionStorageService: SessionStorageService,
		private engineService: EngineService,
		public dialogRef: MatDialogRef<SaveWorkModalComponent>
	) {
	}
	
	ngOnInit() {
		this.engineService.setGizmoControlVisible(false);
		$(SELECTORS.overlay_container).addClass('internal');
	}
	
	ngOnDestroy() {
		this.engineService.setGizmoControlVisible(true);
		$(SELECTORS.overlay_container).removeClass('internal');
	}
	
	onDownloadImage() {
		this.downloading = true;
		const renderCanvas = $(SELECTORS.render_canvas)[0];
		
		html2canvas(renderCanvas, { backgroundColor: '#F3F5F8' }).then((res) => {
			const image = res.toDataURL();
			
			const link = document.createElement('a');
			link.download = 'ziptrak-design-project.jpeg';
			link.href = image;
			link.click();
			link.remove();
			
			this.downloading = false;
		});
	}
	
	onSaveWork() {
		this.saving = true;
		this.saveSessionBlind.putSaveBlindsPromise().then(() => this.saving = false);
	}
	
	close(): void {
		this.dialogRef.close();
	}
}
