import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ShareService } from '@core/services/share-data/share-data.service';
import { ScreenshotService } from '@core/services/screenshot/screenshot.service';
import { OverviewLinkHandlerService } from '@core/services/overview-link-handler/overview-link-handler.service';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';
import { Subscription } from 'rxjs';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { EngineService } from '@core/services/engine/engine.service';
import { STORAGE_NAMES } from '@root/app.config';

import * as _ from 'lodash';

@Component({
	selector: 'app-add-blind',
	templateUrl: './add-blind.component.html',
	styleUrls: ['./add-blind.component.scss', './add-blind.responsive.scss']
})
export class AddBlindComponent implements OnInit, OnDestroy {
	getBlindName: Subscription;
	
	data = [];
	blindId;
	setBlindInfoStatus = false;
	isModelLoaded: string;
	overviewStatus: boolean;
	getBlindInfoStatus: Subscription;
	getBlindEmpty: Subscription;
	getModelLoaded: Subscription;
	getBlindNameStatus: Subscription;
	getBlindStatus: Subscription;
	getSceneCreated: Subscription;
	getBlindType: Subscription;
	getAddBlindEvent: Subscription;
	
	constructor(
		private shareDataService: ShareService,
		private screenShotService: ScreenshotService,
		private overviewLinkHandlerService: OverviewLinkHandlerService,
		private setupPageService: SetupPageService,
		private changeDetection: ChangeDetectorRef,
		private sessionStorageService: SessionStorageService,
		private engineService: EngineService
	) {
	}

	ngOnInit(): void {
		const blindDataStorage = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
		this.getModelLoaded = this.shareDataService.getModelLoaded.subscribe(res => {
			this.setBlindInfoStatus = res;
			this.changeDetection.markForCheck();
		});
		this.getBlindInfoStatus = this.shareDataService.getStatus.subscribe(res => {
			this.setBlindInfoStatus = res;
			this.changeDetection.markForCheck();
		});
		this.getBlindStatus = this.shareDataService.getEmptyBlindData.subscribe(res => {
			this.overviewStatus = res;
			this.changeDetection.markForCheck();
		});
		this.getSceneCreated = this.shareDataService.getSceneCreated.subscribe(res => {
			this.isModelLoaded = res;
		});
		this.getBlindType = this.shareDataService.getBlindType.subscribe(res => {
			this.onSelectOption(res);
		});
		
		this.overviewStatus = _.isEmpty(blindDataStorage);
		this.getBlindName = this.shareDataService.getBlindName.subscribe(this.hasBlinds.bind(this));
		this.getBlindEmpty = this.shareDataService.getBlindEmpty.subscribe(this.hasBlinds.bind(this));
		this.getBlindNameStatus = this.shareDataService.getStatus.subscribe(this.blindStatusHandler.bind(this));
		this.getAddBlindEvent = this.shareDataService.getAddBlind.subscribe(this.onAddBlindHandler.bind(this));
	}

	ngOnDestroy(): void {
		this.getModelLoaded.unsubscribe();
		this.getBlindInfoStatus.unsubscribe();
		this.getBlindName.unsubscribe();
		this.getBlindEmpty.unsubscribe();
		this.getBlindNameStatus.unsubscribe();
		this.getBlindStatus.unsubscribe();
		this.getBlindType.unsubscribe();
		this.getSceneCreated.unsubscribe();
		this.getAddBlindEvent.unsubscribe();
	}

	hasBlinds(isBlindEmpty): void {
		const productType = this.setupPageService.getProductType();
		this.overviewStatus = _.isBoolean(isBlindEmpty) && isBlindEmpty;
		
		if (this.overviewStatus && productType === 'all') {
			this.setupPageService.sendBlindTypeForRender(true);
		}

		this.changeDetection.markForCheck();
	}

	blindStatusHandler(status): void {
		const blindDataStorage = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
		
		if (!_.isEmpty(blindDataStorage)) {
			this.overviewStatus = !status;
		}

		this.changeDetection.markForCheck();
	}

	onAddBlindHandler(saveScreenShot = true): void {
		const productType = this.setupPageService.getProductType();

		if (saveScreenShot) {
			this.saveCurrentBlindScreenshot();
		}

		if (productType === 'all') {
			this.setupPageService.sendBlindTypeForRender(false);
		} else {
			this.engineService.createScene(productType);
			this.sessionStorageService.setSession(productType, STORAGE_NAMES.zip_blind_type);
			this.onSelectOption(productType);
		}
	}
	
	onSelectOption(blindType: string): void {
		const hasSessionData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
		const defaultBlindName = blindType === 'outdoor' ? 'Ziptrak® Outdoor Blind' : 'Ziptrak® Interior Blind';
		
		this.blindId = Math.floor(1000 + Math.random() * 9000);
		
		const defaultBlindTemp = [{
			default_name: defaultBlindName,
			blind_id: this.blindId,
			type: blindType
		}];
		
		if (_.isEmpty(hasSessionData)) {
			this.data = defaultBlindTemp;
		} else {
			this.data = hasSessionData.concat(defaultBlindTemp);
		}

		this.sessionStorageService.setBlindData(this.data, STORAGE_NAMES.zip_blind_data);
		this.sessionStorageService.setBlindData(this.blindId, STORAGE_NAMES.zip_current_blind_id);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_last_opened_blind_id);
		this.shareDataService.changeData(this.data);
		this.shareDataService.setBlindSelect({ id: this.blindId, status: 'new' });
	}

	overviewLinkHandler(): void {
		this.overviewLinkHandlerService.link();
	}

	saveCurrentBlindScreenshot(): void {
		const currentBlindId = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_current_blind_id);
		const lastOpenedBlindId = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_last_opened_blind_id);
		const hasSessionData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
		
		if (!_.isEmpty(hasSessionData)) {
			this.screenShotService.saveScreenShot(currentBlindId || lastOpenedBlindId, true);
		}
	}
}
