import {
	Component,
	OnInit,
	OnDestroy,
	ChangeDetectionStrategy,
	ViewChild,
	ElementRef,
	ChangeDetectorRef,
	AfterViewInit,
	HostListener
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { CONFIG, STORAGE_NAMES } from '@root/app.config';
import 'simplebar';

declare var $: any;

@Component({
	selector: 'app-tool-bar',
	templateUrl: './tool-bar.component.html',
	styleUrls: ['./tool-bar.component.scss', './tool-bar.responsive.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolBarComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('toolBarContainer', { read: ElementRef }) public toolBarContainer: ElementRef<any>;
	@ViewChild('mobileToolBarContainer', { read: ElementRef }) public mobileToolBarContainer: ElementRef<any>;

	getLoadingSpinner: Subscription;
	simpleBarStatus: boolean;
	status = true;
	
	@HostListener('window:resize', ['$event'])
	onResize() {
		this.simpleBarStatus = window.innerWidth >= CONFIG.breakpoints['tablet-landscape'];
	}
	
	constructor(
		private shareDataService: ShareService,
		private sessionStorageService: SessionStorageService,
		private changeDetection: ChangeDetectorRef
	) {
	}
	
	ngOnInit(): void {
		this.setLoadingHandler();
		this.onResize();
	}
	
	ngAfterViewInit() {
		this.getLoadingSpinner = this.shareDataService.getLoadingSpinner.subscribe(res => {
			setTimeout(() => {
				$(this.toolBarContainer?.nativeElement || this.mobileToolBarContainer?.nativeElement).toggleClass('loading', res);
			});
			
			this.changeDetection.markForCheck();
		});
	}
	
	setLoadingHandler(): void {
		const savedStatus = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_session_saved) || window.location.search;
		
		if (savedStatus) {
			setTimeout(() => {
				$(this.toolBarContainer?.nativeElement || this.mobileToolBarContainer?.nativeElement).addClass('loading');
			});
		}
	}
	
	ngOnDestroy(): void {
		this.getLoadingSpinner.unsubscribe();
	}
}
